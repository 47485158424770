import React, { useState, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import LinearProgressWithLabel from "../components/LinearProgressWithLabel";
import { valueData } from "../provider/ValueProvider";
import usePairedValues from "../hooks/usePairedValues";
import { StyledButton, StyledButtonValue } from "../components/Customs";
import env from "../env-config";
import Spacer from "../components/Spacer";
import useContentful from "../hooks/useContentful";
import LoadingWrapper from "../components/LoadingWrapper";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { getAnalytics, logEvent } from "firebase/analytics";
import Wrapper from "../components/Wrapper";
import CustomStepper from "../components/CustomStepper";
import { Grid, Typography } from "@mui/material";
import { DEFAULT_LANGUAGE, LangContext } from "../provider/LanguageProvider";
import { firebaseAuth } from "../provider/AuthProvider";
import { firestoreMethods } from "../firebase/firestoreMethods";

function Focus() {
  const navigate = useNavigate();
  const location = useLocation();

  const { values, handleUpdate, persistValues, initialized, getValue } = useContext(valueData);
  const { user, setUser } = useContext(firebaseAuth);

  const fields = useContentful("focus");
  const [index, setIndex] = useState(location.search.replace("?index=", "") * 1);
  const [showStartVideo, setStartShowVideo] = useState(env.dev.videos);
  const [showEndVideo, setEndShowVideo] = useState(false);

  const { language } = useContext(LangContext);

  const paired = usePairedValues(values);
  const progress = (index / paired.length) * 100;

  function bump(name) {
    const current = getValue(name);

    handleUpdate(current, { focused: current.focused + 1 });

    const analytics = getAnalytics();
    logEvent(analytics, "screen_view", {
      screen_name: `focused_index_${index}`,
    });

    if (index + 1 < paired.length) {
      setIndex(index + 1);
    } else {
      user.appUser.availableTests = Math.max(user.appUser.availableTests - 1, 0);
      setUser(user);
      firestoreMethods.updateUser(user.id, { availableTests: user.appUser.availableTests });

      setEndShowVideo(true);
    }
  }

  async function handlePersistAndPush() {
    if (user && user.appUser && user.appUser.hasSurvey) {
      console.log("user must fill in a survey. navigate to survey");
      navigate("/survey");
    } else {
      console.log("persist resutls and go to results page");
      const id = await persistValues(user.uid);
      navigate(`/results/${id}`);
    }
  }

  if (values.length === 0 && initialized) {
    navigate("/");
  }

  if (values.length === 0 || !fields) {
    return <LoadingWrapper />;
  }

  if (paired.length === 0 || index > paired.length) {
    return null;
  }

  const value0 = paired[index][0];
  const value1 = paired[index][1];
  if (showStartVideo) {
    return (
      <Wrapper>
        <Typography variant="h6" gutterBottom>
          {fields.startVideoTitle}
        </Typography>
        <Spacer />
        {/* <YoutubeEmbed id={fields.startVideoYoutubeId} /> */}
        <Spacer />
        <Typography variant="h6" gutterBottom>
          {documentToReactComponents(fields.startVideoDescription)}
        </Typography>

        <StyledButton
          variant="contained"
          color="primary"
          onClick={() => {
            setStartShowVideo(false);
            window.scrollTo(0, 0);
          }}
        >
          {fields.startVideoButton}
        </StyledButton>
      </Wrapper>
    );
  }

  if (showEndVideo) {
    return (
      <Wrapper>
        <Typography variant="h6" gutterBottom>
          {fields.endVideoTitle}
        </Typography>
        <Spacer />
        {/* <YoutubeEmbed id={fields.endVideoYoutubeId} /> */}
        <Spacer />
        <Typography variant="h6" gutterBottom>
          {user && user.appUser && user.appUser.hasSurvey
            ? documentToReactComponents(fields.endSurveyDescription)
            : documentToReactComponents(fields.endVideoDescription)}
        </Typography>

        <StyledButton variant="contained" color="primary" onClick={handlePersistAndPush}>
          {user && user.appUser && user.appUser.hasSurvey
            ? fields.endSurveyButton
            : fields.endVideoButton}
        </StyledButton>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <CustomStepper activeStep={1} />
      <Spacer />
      <Typography variant="h6">{fields.title}</Typography>
      <Spacer />

      <Grid container spacing={1}>
        <Grid item xs={12} sm={6}>
          <StyledButtonValue
            variant="contained"
            fullWidth
            size="large"
            color="primary"
            onClick={() => bump(value0.name)}
          >
            {value0.value.translations[language]
              ? value0.value.translations[language].value
              : value0.value.translations[DEFAULT_LANGUAGE].value}
          </StyledButtonValue>
        </Grid>

        <Grid item xs={12} sm={6}>
          <StyledButtonValue
            variant="contained"
            fullWidth
            size="large"
            color="primary"
            onClick={() => bump(value1.name)}
          >
            {value1.value.translations[language]
              ? value1.value.translations[language].value
              : value1.value.translations[DEFAULT_LANGUAGE].value}
          </StyledButtonValue>
        </Grid>
      </Grid>

      <Spacer i={2} />
      <LinearProgressWithLabel value={progress} />
    </Wrapper>
  );
}

export default Focus;
